.register_form_parent{
    color: var(--ffffff, #FFF); 
}

.register_form_head{
    font-size: 2.25rem;
    font-style: normal;    
    line-height: 150%;
    letter-spacing: 0.04rem;  
    width: 65%;
    font-weight: 600;
}


.register_form_container > div:nth-child(1){
    margin-top: 1.5rem;
}

.register_form_container > div:nth-child(2){
    margin: 1.5rem 0rem;
}


.register_form_register_btn{
    display: flex;
    justify-content: center;
    column-gap: 10px;
}

.register_password_validation_container{
    height: 9rem;
    padding: 1.5rem 0rem;
}

.register_valid_error{
    color: red;
   margin-top: 5px;
}

.register_valid_cross{
    background-color: red;
    font-size: 15px !important;
    border-radius: 50%;   
    font-weight: bold; 
}

.register_valid_tick{
    background-color: #34D399;
    font-size: 15px !important;
    border-radius: 50%;
    font-weight: bold;
}

.register_valid_grey{
    background-color: grey;
    font-size: 15px !important;
    border-radius: 50%;
    font-weight: bold;
}

.register_icon_container{
    font-size: 5px !important;
}

.regsiter_display_flex{
    display: flex;
    column-gap: 10px;
    margin: 5px 0px;
    align-items: center;
}

.register_border_error > div{
    border: 1px solid #F87171 !important;
}

.regsiter_account_grey{
    background: #636E72 !important;
    color: white !important;
    font-weight: 900;
}

.register_create_profile_sub_heading{
    font-size: 1.3rem;
    margin-top: 1rem;       
}

.register_create_profile_form_parent > div{
    margin: 1.5rem 0px;
}

.register_grey_font{
    color: #6C7278;
}

.register_airport_option{
    display: flex;
    align-items: center;
    color: white;
    column-gap: 1rem;
}

#regsiter_option_style{
    max-height: 10rem;
}

#regsiter_option_style>li{
    border-radius: 10px;
    margin: 10px 5px;
    padding: 10px 5px;
}

#regsiter_option_style>li:hover,#regsiter_option_style>li[data-focus="true"]{
    background-color: #1A1C1E;    
}

#regsiter_option_style>li[aria-selected="true"] {
    background-color: #1A1C1E !important; 
}


.register_selected_option{
    background-color: #1A1C1E;
}

.regsiter_phone_input{
    width: 100% !important;
    padding: 18.5px 14px 18.5px 70px !important;
    background: transparent !important; 
    color:  white !important;
}

.regsiter_contaitner_class > div{
    width: 60px;
}

.regsiter_contaitner_class > div::after {
    content: '';
    position: absolute;
    top: 25%; 
    right: 0;
    width: 1px;
    height: 50%;
    background-color: #CBD5E1;
  }

.react-tel-input .selected-flag {
    width: 100% !important;
}

.react-tel-input{
    border: 1.8px solid #636E72;
    border-radius: 10px;
}


.regsiter_phone_input[data-focusvisible-polyfill="true"]{
    background-color: #1A1C1E;    
    box-shadow: none !important;
    border:1.5px solid #FFD27E !important;
}

.register_drop_down_class {
    background-color: #3E4247 !important;
    border: 1px solid white !important;
    border-top: none !important;
}

.register_drop_down_class li:nth-child(1){
    background-color: #3E4247 !important;
}

.register_drop_down_class li:nth-child(1):hover{
    background-color: #3E4247 !important;
}

.register_drop_down_class li:hover,.register_drop_down_class li[aria-selected="true"]{
    background-color: #1A1C1E !important;
}

.phone_number_dropdown {
    background-color: #3E4247 !important;
    border: 1px solid white !important;
    border-top: none !important;
    color:white
    
    /* width: 34rem !important; */
}

.phone_number_dropdown li:nth-child(1){
    background-color: #3E4247 !important;
}

.phone_number_dropdown li:nth-child(1):hover{
    background-color: #3E4247 !important;
}

.phone_number_dropdown li:hover,.phone_number_dropdown li[aria-selected="true"]{
    background-color: #1A1C1E !important;
}

.dietry_requirements .MuiPaper-root {
    background-color: #3E4247;
    color: white;
    border: 1px solid white;
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-top: -4px;
}

.dietry_requirements .MuiMenuItem-root.Mui-selected {
    background-color: #0D1015;
    color: white; 
}




.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before{
    border: none !important;
    box-shadow: none !important;
}

.hoverCursor:hover{
    cursor: pointer;
}