.booking_section{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.order_detail_view_heading_parent{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.order_detail_view_search{
    width: 43%;
    position: relative;
    left: -2.2rem;
}

.order_detail_table_head_cell{
    display: flex;
    align-items: center;
    column-gap: 0.2rem;
}

.order_detail_view_menu_box{
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
}

.order_detail_view_menu_item{
    display: flex;
    font-size: 1.2rem;
    column-gap: 0.15rem;
    align-items: center;
}
.order_detail_view_menu_sorted{
    border: 1px solid moccasin !important;
}

#order_detail_view_menu_sort_button{
    padding: 0.2rem;
    border-radius: 50%;
    border: 1px solid #1D1F21;
}

#order_detail_view_menu_sort_button:hover{
    cursor: pointer;
}

.order_detail_view_paid{
    color: #20E70E !important;

}

.order_detail_view_unpaid{
    color: #FE4023 !important;  
}

.order_detail_view_pending{
    color: #FFCE00 !important;  
}

.cf_avinode_no_record_found{
    border: 1px solid #94A3B8;
    background-color: #121315;
    color: #94A3B8;
    border-radius: 10px;
    width: 100%;
    padding: 1rem;
}

/* Order Management styles start*/

.order_detail_black_round, .order_detail_black_passenger{
    color: white;
    background-color: black;
    border-radius: 10px;
}

.order_detail_black_passenger{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.order_detail_head_edit{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order_detail_each_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem 1.5rem;
    border-bottom: 1px solid #3E4247;
}

.order_detail_less_size{
    border-bottom: none;
    padding: 0.6rem 1.2rem;
}

.order_detail_doc_parent{
   display: flex; 
   column-gap: 1rem;
   align-items: center;
}

.order_detail_black_round > :last-child,.order_detail_last_child > :last-child {
    border-bottom: none;
}
.order_detail_color_grey{
    color: #888;
}

.order_detail_column_cont{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.order_detail_view_accordion_parent{
    display:flex;
    background-color:#3E4247;
    align-items:flex-start;
    border-radius:10px;
    overflow:hidden;
}

.order_detail_view_edit_expand{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.order_detail_icon_buton{
    padding: 1.25rem 0.5rem !important;
}

.order_detail_doc{
    padding: 0rem 1.5rem;
    border-bottom: 1px solid #3E4247;
}


.order_detail_accord_header::before {
    content: "" !important;
    position: absolute !important;
    left: 0 !important;
    top: 20% !important; 
    bottom: 20% !important; 
    width: 2.2px !important; 
    background-color: #FFD07A !important; 
  }

.order_detail_passenger_button_cont{
    display: flex;
    align-items: center;
    column-gap: 2rem;
}

.order_detail_module_line{
    font-size: 2rem;
    color: #3E4247;
}


.order_detail_require_parent{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    border-bottom: 1px solid #3E4247;
    padding: 1.2rem 0rem;
}

.order_detail_require_content{
    font-size: 1rem;
    color: #888;
}

.order_detail_require_head{
    font-size: 1.2rem;

}

.order_detail_debit_icon_parent{
    top: -5px;    
    position: relative;
}

.order_detail_order_summary{
    background-color: #121415;
    padding: 1rem;
}

.order_detail_order_summary_child{
    background-color: #1A1C1E;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.order_detail_view_order_each{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 1rem;
}

.order_detail_view_dashend_border{
    border: 1px dashed #94A3B8;
}

.order_detail_view_cards_parent{
    background-color: black;
    padding: 1rem;
    border-radius: 10px;
    color: white;
}

.order_detail_view_cards_head{
    font-size: 1.2rem;
    font-weight: bold;
}

.order_detail_view_radio_parent{
    display: flex;
    column-gap: 1rem;
    color: white;
    align-items: center;
    padding: 1rem 1.3rem;
    background-color: black;
    border-bottom: 1px solid #6C7278;
}

.order_detail_view_image_parent{
    border: 1px solid #6C7278;
    height: 2.4rem;
    width: 3rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem;
}

.order_detail_expiry_font{
    color: #94A3B8;
    font-size: 0.9rem;
}

.order_detail_card_name_expiry_parent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 0.4rem;
}

.invoice_section{
    background-color: #1A1C1E;
    padding: 1rem;
    color: white;

}

.order_detail_horizantal_row{
    border-top: 1px solid #475569;
    width: 100%;
}

.large_modal_styling{
    max-width: 70rem;
    width: 65% !important;
    min-width: 40rem;
    /* max-height: 90vh !important; */
}

.order_detail_upload_box{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14rem;
    width: 100%;
    border: 1px dashed #CBD5E1;
    border-radius: 10px;
}

.order_detail_upload_box:hover{
    cursor: pointer;
    border: 1px dotted white;
}

.phone_number_passenger{
    width: 100% !important;
    padding: 18.5px 14px 18.5px 70px !important;
    background: transparent !important; 
    color:  white !important;
    height: 4rem;
}

.phone_number_passenger[data-focusvisible-polyfill="true"]{
    background-color: #1A1C1E;    
    box-shadow: none !important;
    border:1px solid #CACACA !important;
}
.react-datepicker-popper{
    z-index: 5 !important;
}

.invoice_parent{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    background-color: black;
    color: white;
    padding: 2rem;
    border-radius: 10px;
    max-height: 59vh;
    overflow: auto;
}


.invoice_parent_flex_column{
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}

.invoice_header{
    background-color: #2D3034;
    color: white;
    padding: 1rem 1.5rem;
    border-radius: 5px;
}

.add_item_invoice{
    justify-content: end;
    display: flex;
}

.view_details_specific{
    background-color: #1A1C1E;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
    row-gap: 0.2rem;
}

.status_dot_parent{
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
}
.status_dot{
    border-radius: 50%;
    height: 0.9rem;
    width: 0.9rem;
}

.order_details_row >td{
    font-weight: 500 !important;

}

.save_passenger_cont{
    background-color: #1A1C1E;
    color: white;
    display: flex;
    column-gap: 0.5rem;
    padding: 0.5rem;
    align-items: center;
    border-radius: 10px;
}

.invoice_section_head{
    font-size: 1.3rem;
}

.wordBreak{
    word-break: break-all;
}

.invoice_middle_container{
    border-left: 1px solid #3E4247;
    border-right: 1px solid #3E4247;
}

.order_detail_view_accordion_file{
    max-height: 15rem;
    overflow-y: auto;
    scrollbar-color: #636E72 #3E4247; 
    scrollbar-width: thin; 
}

.order_detail_view_accordion_file::-webkit-scrollbar {
    width: 5px;
  }

.order_detail_view_accordion_file::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey !important; 
    border-radius: 10px !important;
    background: #3E4247 !important; 
  }
   
.order_detail_view_accordion_file::-webkit-scrollbar-thumb {
    background: #636E72 !important; 
    border-radius: 20px !important;
  }

.dash_table_row:hover td {
    cursor: pointer;
    border-top: 1px solid #FFD485;
    border-bottom: 1px solid #FFD485;
}

.dash_table_row:hover td:first-child {
    border-left: 1px solid #FFD485 !important; 
}

.dash_table_row:hover td:last-child {
    border-right: 1px solid #FFD485 !important;
}

.client_table_row td{
    padding: 10px 16px!important;
}

.client_name_id{
    cursor: pointer;
    text-decoration: underline;
}

.client_name_id:hover{
    color: gold;
}

.register_drop_down_class li:hover{
    color: white !important;
}