.forp_gen_modal_margin{
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 150%; /* 1.6875rem */
}

.forp_password_timer_resend{
    display: flex;
    justify-content: space-between;
}

.forp_otp_form{
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    justify-content: space-between;
}

.forp_otp_head{
    font-size: 2rem !important;
}

.forp_continue_button{
    margin-bottom: 1rem;
}

.modal_check_icon_cont{
    padding: 1.5rem;
    border-radius: 1.25rem;
    background: var(--gold-grad, linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%));
    box-shadow: 0px 24px 34px -10px rgba(34, 58, 140, 0.30); 


    position: absolute;
    top: -14%; /* Aligns the top of the child with the top of the parent */
    left: 50%; /* Centers the child horizontally in the parent */
    transform: translateX(-50%); /* Adjusts the horizontal centering to account for the child's width */
    /* width: 100px; 
    height: 50px;  */
    background-color: #f00; /* Optional: Adds a background color to the child element for visual reference */
}   

.modal_check{
    vertical-align: middle;
    background-color: black;
    padding: 6.5px;
    border-radius: 0.5rem;
}