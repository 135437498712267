.advance_search_main {
  background-color: black;
  padding: 20px;
  color: white;
}

.advance_search_button_container>button {
  width: 32%;
}


.advance_search_option {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.advance_search_margin{
  margin: 1.3rem 0rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 1.5rem;
}

.advance_search_margin_select{
   margin-top: 0.8rem; 
   margin-bottom: 0.3rem;
}

.advance_search_menu {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  margin-top: -1.2rem !important;
  background-color: #3E4247 !important;
  color: white;
  padding: 1rem 0rem !important;
  border: none;
  border-radius: 5px 5px 10px 10px;
  min-width: 20rem;
  width: 100%;
}

.react-datepicker__day-name {
  padding: 0 6px;
  color: white;

}

.react-datepicker__current-month{
  color: white;
}

.react-datepicker__navigation {
  top: 20px;
}

.react-datepicker__navigation--previous {
  left: 16px;
}

.react-datepicker__time-icon {
  color: red;
  fill: red;
}

.react-datepicker__day--today{
  border: 1px solid grey;
}

.react-datepicker__navigation--next {
  right: 16px;
}

.react-datepicker__input-time-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 80%;
  float: none !important;
  margin: auto;
}

input[type="time"].react-datepicker-time__input {

  color: inherit;
  background-color: inherit;
  border: 1px solid white;
  padding: 2px;
  border-radius: 4px;


}

.react-datepicker__day--keyboard-selected {
  background-color: inherit;
}

.react-datepicker__day:hover {
  color: black;
  border-radius: 50%;
}

.react-datepicker__day--selected {
  color: black !important;
  background-color: #FFE3AE !important;

}



.react-datepicker__header {
  background-color: inherit !important;
  border-bottom: none !important;
  color: white;
}

.react-datepicker-popper[data-placement^=top]{
  padding-bottom: 0px;
}

.react-datepicker__day--disabled,.react-datepicker__day--today{
  color: lightgray;
  cursor: not-allowed; 
  font-weight: 100;
}

.advance_search_select_box {
  display: flex;
  align-items: center;
}

.advance_search_select_box_child {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
  margin-left: 10px;
}

.advance_search_select_box div{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.advance_search_exhange {
  position: absolute;
  color: #2D3034;
  padding: 7px;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%), linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%);
}

.advance_search_exchange_container{
  padding: 7px;
  cursor: pointer;
  background: linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%), linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%);
  border-radius: 50%;
  color: #2D3034;
}

.advance_search_form_parent{
  display: flex;
  flex-direction: column;
  row-gap: 1.3rem;
  /* margin: 2.3rem 0; */
}

.advance_search_picker .MuiPaper-root {
  background-color: #3E4247;
  color: white;
  border-radius: 0px 0px 10px 10px;
  margin-top: 1px;
}

.advance_search_picker .MuiMenuItem-root.Mui-selected {
  background: #FFD27E;
  color: black; 
}

.advance_search_picker .MuiListItem-root{
  margin: 4px 6px;
  border-radius: 10px;
}

.advance_search_passenger_placeholder{
  position: relative;
  margin-bottom: 3px;
}

.advance_search_passenger {
  display: flex;
  column-gap: 15px;
  align-items: center;
}

.react-datepicker-time__caption {
  font-size: 1rem;
}

.react-datepicker__day {
  padding:0.4rem;
  border-radius: 50%;
  color: white;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day--disabled,.react-datepicker__day--today{
  color: lightgray;
  cursor: not-allowed; 
  font-weight: 100;
}

.advance_search_select_box {
  display: flex;
  align-items: center;
}

.advance_search_select_box_child {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
  margin-left: 10px;
}

.advance_search_select_box div{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.advance_search_exhange {
  position: absolute;
  color: #2D3034;
  padding: 7px;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%), linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%);
}

.advance_search_exchange_container{
  padding: 7px;
  cursor: pointer;
  background: linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%), linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%);
  border-radius: 50%;
  color: #2D3034;
}

.advance_search_form_parent{
  display: flex;
  flex-direction: column;
  row-gap: 1.3rem;
  /* margin: 2.3rem 0; */
}

.advance_search_picker .MuiPaper-root {
  background-color: #3E4247;
  color: white;
  border-radius: 0px 0px 10px 10px;
  margin-top: 1px;
}

.advance_search_picker .MuiMenuItem-root.Mui-selected {
  background: #FFD27E;
  color: black; 
}

.advance_search_picker .MuiListItem-root{
  margin: 4px 6px;
  border-radius: 10px;
}

.advance_search_picker{
  z-index: 2 !important;
  margin-top: -7px !important;
}

.mui_select_unkownn > div:first-child {
  z-index: 5;
  position: relative;
}

.advance_search_passenger_placeholder{
  position: relative;
  margin-bottom: 3px;
}

.advance_search_button_leg_cont{
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  padding: 0rem 0.4rem;
  padding-left: 0.6rem;
}

.advance_search_leg_header{
  display: flex;
  justify-content: space-between;
  padding: 0px 7px;
}
.advance_leg_head{
  font-size: 1.25rem;
}
.advance_search_remove_leg{
  color: #F87171 !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}

.advance_search_horizantal_row{
  width: 99%;
  margin: auto;
}

.advance_search_select_cont{
  padding: 0 0.6rem;
}

.register_drop_down_class li:hover,#flight_list_container li[aria-selected="true"]{
  background-color: #FFD27E !important;
  color: black;
  border-radius: 10px;
}


.advance_search_skeleton_container{
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.react-datepicker__month-container{
  float: none;
}

.custom_time_picker_modal .MuiToolbar-regular,
.custom_time_picker_modal .MuiPickersClock-pin,
.custom_time_picker_modal .MuiPickersClockPointer-pointer {
  background: linear-gradient(to bottom, rgb(255, 208, 120), rgb(255, 227, 175));
}

.custom_time_picker_modal .MuiPickersClockPointer-thumb {
  border: 14px solid rgb(255, 208, 120);
}

.advance_search_one_way_button,.advance_search_round_trip_button,.advance_search_mutli_trip_button{
  height: 4rem !important;
}

