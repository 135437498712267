.masterlay_modal{
    min-width: 442px;
    overflow: auto;
    width: 28rem;
    position: relative;
    color: white;
    top: 55%;
    padding: 15px;
    background:  rgba(0, 0, 0, 0.90);
    padding: 2rem 2.5rem;
    border-radius: 0.5rem;
  }

  .new_div_child{
    display: flex;
    justify-content: center;
    align-items: center;
    row-gap: 1.2rem;
    flex-direction: column;
  }

  
.masterlay_logo {
    width: 300px; 
    height: 100px;
    object-fit: contain; 
    margin-top: 1.2rem;
    flex-direction: column;
  }