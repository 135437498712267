.login_form_parent{
    color: var(--ffffff, #FFF); 
}

.login_form_head{
    font-family: sans-serif;
    font-size: 2.25rem;
    font-style: normal;    
    line-height: 150%;
    letter-spacing: 0.04rem;  
    font-weight: 600;
}


.login_form_container > div:nth-child(1){
    margin-top: 1rem;
}

.login_form_container > div:nth-child(2){
    margin-top: 2rem;
}

.login_form_me_password{
    display: flex;
    justify-content: space-between; 
}

.login_form_register_btn{
    display: flex;
    justify-content: center;
    column-gap: 10px;
}

.login_valid_error{
    color: red;
   margin-top: 5px;
}

.login_valid_error_new{
   position: absolute;
   color: red;
}

.login_border_error > div{
    border: 1px solid #F87171 !important;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px #000 inset !important;
    -webkit-text-fill-color: #fff !important;
    font-family: inherit;
    font-size: inherit;
    border: none !important;
    outline: none !important;
  }

  .new_div{
    display: flex;  
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.masterlay_modal {
top: 0%;
}

@media (max-width: 1440px) {
  .new_div{
      display: block;
  }
}

@media (min-height: 1080px) {
  .masterlay_logo_parent {
    position: absolute !important;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
