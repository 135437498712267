/* .cf_avinod */
.cf_avinod_main {
    color: white;
    row-gap: 1rem;
    justify-content: unset;
}

.cf_avinod_loader_screen{
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.cf-avinode-search{
  text-transform: capitalize;
}

.cf_avinod_legs_parent{
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.4rem;
    background-color: black;
    border-radius: 10px;
    margin-top: 1rem;
}

.cf_avinod_leg_main{
    border-radius: 10px 10px 0px 0px;
}

.cf_avinod_arrow_search{
    margin-right: 0.8rem;
}

.cf_avinod_each_leg_parent{
    padding: 20px;
}

.cf_avinod_each_leg_head{
    font-weight: 600;
    font-size: 1.35rem;
}



.cf_avinod_content_table{
    display: flex;
    flex-direction: column;
    row-gap:1rem;
}

.cf_avinod_big_text_table{
    font-size: 1.5rem;
}

.cf_avinod_mid_text_table{
    font-size: 1.2rem;
}

.cf_avinod_big_flight_available{
    font-size: 1rem;    
}

.cf_avinod_big_gold_color{
    color: #FFD27E;
}

.cf_avinod_dimension_container{
    display: flex;
    align-items: center;
    column-gap: 0.6rem;
}

.cf_avinod_grey_text{
    color:#94A3B8
}

.cf_avinod_flight_black{
    background-color: black;
    padding: 4px;
    font-size: 0.5rem;
    color: green;
    border-radius: 50%;
}

.cf_avinod_flight_red{
    background-color: black;
    padding: 4px;
    font-size: 0.5rem;
    color: red;
    border-radius: 50%;
}

.cf_avinod_box_column{
    display: flex;
    flex-direction: column;
    row-gap: 0.67rem;
}

.rotate-clockwise {
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.cf_avinod_dot{
    color: #94A3B8;
    height: 5px;   
    width: 5px;    
    background-color: grey; 
    border-radius: 50%;     
    display: inline-block;  
    vertical-align: middle; 
}

.cf_avinod_no_result_head{
    font-weight: 800;
    font-size: 2rem;
}

.cf_avinod_no_result_container{
    margin: auto;
    width: 300px;
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
    text-align: center;
    align-items: center;
    padding-bottom: 1.2rem;
}

.cf_avinod_exchange_container{
    padding: 7px;
    cursor: pointer;
    height: 25px;
    align-self: center;
    background: linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%), linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%);
    border-radius: 50%;
    color: #2D3034;
  }


  .cf_avindode_selected_flight{
    display: flex;
    justify-content: space-between;
    background-color: black;
  }

  .cf_avindode_edit_flight_cont{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 1rem;
    row-gap: 1.5rem;
  }

  .cf_avinode_flight_head{
    display: flex;
    column-gap: 1rem;
  }

  .cf_avinode_head_seats{
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;
  }

  .cf_avinode_head_image_seat{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cf_avinode_head_book{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #636E72;
  }

  .cf_avinode_flight_container{
    color: white;
    /* padding: 1rem; */
    background-color: #2D3034; 
    border-radius: 12px;
  }

  .cf_avinode_aircraft_table_cont{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .cf_avinode_aircraft_aligner{
    padding: 0rem 0.6rem;
  }

  .cf_avindode_filter_modal{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 20px !important;
  }

  .cf_avindode_accordion_content{
    background-color: #1A1C1E;
    display: inherit !important;
  }

  .cf_avindode_accordion_content_filter_option{
    padding: 0px !important;
    border-radius: 0px 0px 10px 10px !important;
  }

  .cf_avindode_accordion_header{
    background-color: inherit !important;
    padding: 5px 1rem !important;
  }

  .cf_avindode_option_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #2D3034 !important;
    padding: 5px 1rem;
    padding-right: 0.4rem !important;

  }

  .cf_avindode_submit_container{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .price_col_table{
    left: -1.2rem;
    position: relative;
  }

  .aircraft_type_col{
    width: 26.5%;
  }

  .main_image_aircraft{
    height: 4.3rem;
    width: 6.5rem;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1A1C1E;
  }

  .main_image_aircraft > img{
    background-size: cover;
  }

  .flight_image_aircarft{
    color:  #636E72 !important;
    transform: rotate(90deg);
    font-size: 2rem !important;
  }

  .view_aircraft_text{
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    color: #FFD27E !important;
    cursor: pointer;
  }

  .leg_representer{
    padding: 1rem !important;
  }

  .view_flight_details_parent{
    padding: 1rem;
    margin-top: 1rem;
    background-color: black;
    border-radius: 10px;
  }

  .image_aircarft_catalogue{
    height: 16rem;
    overflow: hidden;
    /* padding: 1rem; */
  }

  .info_row_aircarft_parent{
    border-radius: 10px;
    border: 1px solid #2E3135;
  }

  .info_row_aircarft{
    padding: 1rem;
    display: flex;
    justify-content: space-between;
  }

  .book_now_footer{
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  
  .book_now_footer > :first-child {
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;
  }

  .flight_info_parent{
    background-color: #1A1C1E;
    padding: 0.5rem;
    border: 1px solid #6C7278;
    border-radius: 10px;
  }
  
  .info_shower{
    border-radius: 10px;
    border: 1px solid #6C7278;
  }
  
  .space_maker{
    padding: 0.5rem;
    font-weight: 500;
    font-size: 1.05rem;
    min-height: 3rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .column_flex{
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
  }
  
  .from_to_fields{
    border-radius: 10px;
    border: 1px solid #6C7278;
    display: flex;
    column-gap: 1.3rem;
  }
  
  .sync_icon_parent{
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%);
  }
  
  .sync_alt_icon{
    color:#ACB5BB !important;
    border:1px solid #ACB5BB !important;
    border-radius:5px !important;
    padding: 0.3rem;
    background-color: #1A1C1E;
  }
  
  .airport_country{
    display: flex;
    flex-direction: column;
    row-gap: 0.04rem;
  }