.nav_accord_details{
    padding: 0 !important;
}

.nav_accord_details>ul{
    width: 80%;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 10px 10px;
    margin-left: 1.2rem;
    border-left: 1px solid #334155;
}


.sidebar_item_text > span{
    font-size: 1.2rem;
}

.sidebar_delete_acc{
    color: #F87171;
}


.navbar {
    width: 100%;
    height: 7rem;
    color: white;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--neutrals-cool-gray-700, #334155);
    background-color: #1E2022;
    top: 0; 
    left: 0;
    position: fixed; 
    z-index: 10;
    justify-content: space-between;
    min-width: 900px;
}

.footer {
    height: 7rem;
    color: white;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--neutrals-cool-gray-700, #334155);
    background: rgba(45, 48, 52, 0.20);
    backdrop-filter: blur(5px);
    bottom: 0; 
    left: 0;
    position: fixed; 
    z-index: 1;
    justify-content: space-between;
    width: calc(100% - 240px);
    margin-left: 270px;
}

.container {
    display: flex;
    max-width: 1920px;
    margin: auto;
}

.sidebar {
    max-width: 250px;
    width: 18%;
    min-width: 200px;
    color: white;
    padding: 20px;
    border-right: 1px solid var(--neutrals-cool-gray-700, #334155);
    background-color: #1E2022;
    position: fixed; 
    top: 7.1rem; 
    left: 0; 
    z-index: 9; 
    height: calc(100% - 7.1rem); 
    overflow-y: auto; 
}

.content {
    margin: auto;
    margin-top: 7rem;
    margin-left: 300px;
    padding: 20px;
    width: 85%;

}

@media screen and (max-width: 1280px) {
    .content {
      margin-left: 280px;
    }
  }

.content_screen {
    overflow: hidden;
    border-radius: 10px;
}

.logo{
    width: 18%;
    max-width: 250px;
    min-width: 200px;
    height: 7rem;
    padding: 0px 20px;
    border-right: 1px solid var(--neutrals-cool-gray-700, #334155);
 }

.logo img {
    /* width: 250px;  */
    width: 100%;
    height: 100%;
}

.nav_content{
    height: 100%;
    display: flex;
    width: 80%;
    margin: auto;
    align-items: center;
    justify-content: space-between;
}

.nav_filter_button_textfield{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}



.nav_filter_icon{
    font-size: 1.8rem !important;
    margin-left: 0.2rem;

}

.nav_name_cont{
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: end;
}

.nav_profile_name{
    display: flex;
    align-items: center;
    /* width: 25%; */
    margin-right: 1.8rem;
    max-width: 30rem;
    justify-content: end;
    column-gap: 1.5rem;
}


.sidebar_menu_item{
    background-color: #1A1C1E !important;
    border-radius: 0.5rem !important;
}

.sidebar_list_item_cont {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}



.sidebar_list_item_cont:last-child{
    margin-bottom: 3rem;
}

.sidebar_list_extrem_width > span{
    width: 120%;
}

.sidebar_unactive_element{
    color: inherit;
    background-color: inherit;
    text-decoration: none;
}

.sidebar_active_element{
    text-transform: none;
    text-decoration: none;
    color: black;
    border-radius: 0.5rem;
}
.sidebar_active_element > div{
    background-color: #FFD17A !important;
}

.sidebar_active_element svg{
    color: black !important;
}

.custom_imported_icon svg path {
    stroke: black;
  }

.nav_list_item{
    border-radius: 0.5rem !important;
    margin-left: 1rem !important;
    padding: 0.3rem 1rem !important;
}

.truncate-container {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .message_container{
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 1rem;
  }

  .filter .MuiPaper-root {
    background-color: #3E4247;
    color: white;
    border: 1px solid white;
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.filter .MuiMenuItem-root.Mui-selected {
    background-color: #0D1015;
    color: white; 
}


/* If sub item has menu, below for remove extra padding*/
.sidebar_list_nav_list_item_menu{
    padding: 0px 16px !important;
}

 @media (min-width: 0px) and (max-width: 1098px) {
    .nav_content{
        justify-content: space-evenly;
    }

    .nav_user_name{
        display: none;
    }
    
}