.footer_global{
    display: flex;
    justify-content: center;
    width: 100%;
}

.contact_us_form_parent{
    background-color: #2D3034;
    padding: 1rem;
    color: white;
    border-radius: 1rem;
}

.search_country_code-box{
    height: 2.5rem;
    width: 95%;
    background-color: #2D3035;
    color: white;
    border-radius: 0.5rem;
}