.dash_main {
  background-color: black;
  margin: auto;
  padding: 20px 25px;
  color: white;
  font-family: sans-serif;
}

.dash_golden_text {
  color: #FFD27E;
  font-size: 24px;
}

.dash_container {
  display: flex;
  justify-content: space-between;
}

.dash_container>div:nth-child(1),
.dash_container>div:nth-child(2) {
  width: 49%;
  align-items: center;
  height: 150px;
}

.dash_revenue_card {
  min-height: 8rem;
  padding: 1rem 1.5rem 0 1.5rem;
  border-radius: 1.25rem;
  flex: 1;
}


.dash_revernue_type {
  font-size: 1.25rem;
}

.dash_revenue_head {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 20px;
}



.dash_tables_parent {
  margin-top: 20px;
  column-gap: 1vw;
  grid-row-gap: 32px;
  justify-content: space-between;
}

.dash_gen_table {
  border-radius: 10px;
  border: 1px solid #2B2E30;
  background: #121315;
  padding: 2rem;
  min-height: 400px;
  width: 49%;
}

@media only screen and (max-width: 1280px) {
  .dash_gen_table {
    width: 100%;
  }
}

@media screen and (min-width: 3061px) {

  .dash_gen_table {
    width: 48%;
  }
}


.dash_table_head {
  height: 5rem;
}

.dash_table_head>th {
  background-color: #1D1F21;
  color: #8E8F90;
  border: none;
  font-size: 0.9rem;
}

.dash_table_row {
  overflow: hidden;
}


.dash_table_container {
  margin-top: 20px;
}


.dash_table_row>td {
  color: white;
  border-bottom: none;
  font-weight: bolder;
  font-size: 0.9rem;
  background-color: #1D1F21;
  border: 1px solid #36393D;
  border-left: none;
  border-right: none;
}




.dash_revenue_container {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.dash_view_button_container {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

.dash_capitalize_text {
  text-transform: capitalize;
}

.dash_success_text {
  color: green !important;
}

.dash_error_text {
  color: #FFCE00 !important;
}

.dash_times {
  color: rgba(255, 255, 255, 1) !important;
  opacity: 0.6;
}

.dash_admin_name {
  font-weight: 550;
}

.dash_rounded-row-first-cell {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 1px solid #36393D !important;
}

.dash_rounded-row-last-cell {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 1px solid #36393D !important;
}



.dash_table_head th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.dash_table_head th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dash_top_header_box {
  background-color: #121315;
  border-radius: 1rem;
  padding: 1rem;
  height: 8rem;
  overflow: hidden;
}

.dash_top_head_gold {
  font-size: 2rem;
  font-weight: bold;
  color: #FFD27E;
}

.dash_card_analytics {
  border: 1px solid #FFD27E;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  min-height: 8rem;
  border-radius: 1rem;
  background-color: #1A1C1E;
  justify-content: center;
}

.dash_figures_parent {
  display: flex;
  column-gap: 0.2rem;
  align-items: baseline;
}

.dash_button-toggle-parent {
  display: flex;
  column-gap: 0.4rem;
  align-items: center;

}

.dash_flex_column {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.dash_flex_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dash_name_welcome {
  font-size: 2rem;

}

iframe {
  display: none;
}

.dash_table_head_client>th {
  background: #2D303466;
  color: #8E8F90;
  border: none;
  font-size: 0.9rem;
}

.dash_table_head_client th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.dash_table_head_client th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.dash_table_row_client {
  overflow: hidden;
}

.dash_table_row_client>td {
  color: white;
  border-bottom: none;
  font-weight: bolder;
  font-size: 0.9rem;
  background: #2D303466;
  border: 1px solid #6C727866;
  border-left: none;
  border-right: none;
}

.dash_inbox_parent{
  border-radius: 10px;
  border: 1px solid #35393D;
  background-color: #222427;
  margin-top: 20px;
}

.dash_inbox_client{
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dash_avatar_cont{
  display: flex;
  column-gap: 1rem;
}


.dash_client_debit_parent{
  position: absolute;
  height: 75%;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}
.dash_client_debit_flex{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dash_client_card_lil_flex{
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
}

.uppercase{
  text-transform: uppercase;
}

@media (min-width: 600px) {
  .client_img_card {
    height: 20rem;
  }
}

@media (min-width: 900px) {
  .client_img_card {
    height: 18rem;
  }
}

@media (min-width: 1200px) {
  .client_img_card {
    height: 20rem !important;
  }
}

@media (min-width: 1920px) {
  .client_img_card {
    height: 12rem !important;
  }
}