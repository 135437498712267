.profile_main_container{
    border-radius: 1rem;
    background: #0D1015;
    padding: 2.5rem;
    margin-bottom: 7rem;
}

.profile_profile_pic_cont{
    display: flex;
    height: 10rem;
    column-gap: 2rem;
    align-items: center;
    overflow: hidden;
}


.profile_pic_container{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
}

.profile_option_style{
    max-height: 4rem;
}

.profie_profile_pic{
    height: 8rem;
    width: 8rem;
    border: 4px solid #FFD17A;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.profile_pic_pen_icon{
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    z-index:1;
}

.new_pic_text{
    height: 100%;
    width: 100%;
    z-index:1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: center;
}

.overlay_pic{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000080;
}

.profile_pen_icon_cont{
    background-color: #FFD17A;
    color: black;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
}

.profie_details_cont{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 80%;
}

.profile_name_style{
    color: #FFF;
    /* font-family: Manrope; */
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem; /* 140% */
}

.profile_user_id{
    color: var(--neutrals-cool-gray-400, #94A3B8);
    /* font-family: Manrope; */
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; 
}

.profile_horizantal_row{
    width: 100%;
    margin: 1.5rem 0rem;
}

.profile_hover_icons{
    cursor: pointer;
    position: absolute;
    z-index: -1;
    right: 10px;
}

.profile_ditry_menu .MuiPaper-root {
    background-color: #3E4247;
    color: white;
    border: 1px solid white;
}

.profile_ditry_menu .MuiMenuItem-root.Mui-selected {
    background-color: #0D1015;
    color: white; 
}

.regsiter_phone_input{
    font-size: 1.1rem !important;
}

.react-tel-input :disabled{
    background:#2D3034 !important;
    border:none !important
}
.profile_edit_icon{
    color: #FFD07A;
}

.profile_edit_icon > path{
    fill-opacity: 1;
}

.MuiSelect-iconOpen {
    transform: rotate(0deg) !important; 
}

.profile_customSelect .MuiSelect-iconOpen {
    transform: rotate(0deg) !important; 
}

.profile_customSelect .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 20px) scale(1);
}

.register_drop_down_class {
    background-color: #3E4247 !important;
    border: 1px solid white !important;
    color: white;
}


.register_drop_down_class li:hover,.register_drop_down_class li[aria-selected="true"]{
    background-color: #1A1C1E !important;
}

.profile_pic_camera{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: white;
    row-gap: 0.5rem;
    border-radius: 50%;
    background-color: #1A1C1E;
}

.profile_avatar{
    height:100%;
    width:100%;
}

#profile_error_properties{
    position: absolute;
    color: red;
}

.profile_submission{
   display: flex; 
   margin: auto;
   width: 40%;
   column-gap: 2.6rem;
}
.profile_pic_edit_buttons_cont{
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    margin-bottom: 2.5rem;
    border-radius: 10px;
    overflow: hidden;
}

.profile_pic_edit_buttons{
    background-color: #1A1C1E !important;
    color: white !important;
    padding: 12px !important;
    text-transform: none !important;
    font-size: 1.2rem !important;
    /* border-radius: 10px !important; */
}


.profile_gen_modal_margin{
    margin-top: 0.7rem;
    margin-bottom: 2.5rem;
    font-weight: 500;
    font-size: 1.25rem;
}

.profile_gen_modal_submit_cont{
    margin-top: 2.5rem;
    display: flex;
    column-gap: 1rem;
}


.profile_admin_info_box{
    background-color:#2D3034;
      padding:1.5rem;
      border-radius:1rem;
      font-weight:bold;
      display:flex;
      row-gap:1.2rem;
      flex-direction:column
}

.profile_admin_row{
    display:flex;
    justify-content:space-between;
    align-items:center;
}

.profile_admin_row> :nth-child(1),.profile_admin_key{
    color:#94A3B8;
    font-size:1.4rem;
    font-weight: 500;
}

.profile_admin_row> :nth-child(2){
    font-size:1.6rem;
    font-weight: 500;
}

.admin_profile_avatar_parent{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.admin_profile_email{
    width: 98%;
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: #2D3034;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.profile_mail_box{
    border-radius: 50%;
    padding: 10px;
    background-color: #1A1C1E;
    width: 1.5rem;
    height: 1.5rem;
}

.profile_mail_parent{
    display: flex;
    column-gap: 1rem;
    align-items: center;

}



.profiel_email_parent{
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;
}

.profile_center_iamge_edit{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
}

.profile_center_iamge_edit_image_box{
    height: 100%;
    border-right:1px solid #585B5F;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.profile_verification_text{
    margin-top: 0.5rem;
    color: #94A3B8;
}

.admin_profile_each_sec{
    padding: 1rem 1.5rem;
}

.diet_dropdown{
    z-index: 0 !important;
    margin-top: -2px;
}

@media (max-width: 1280px) {
    .profile_center_iamge_edit_image_box {
        border-right:none;
    }
  }

  .mui_select_unkownn-1 > div:first-child {
    z-index: 10;
    position: relative;
  }

  .job_title_picker{
    z-index: 6 !important;
  }