.ql-toolbar.ql-snow {
    width: fit-content;
    margin-left: auto;
    margin-bottom: 20px;
    border-radius: 9px;
    border: 1px solid #3E4247;
    padding:6px 10px 11px;
    display: flex;
}
.ql-container.ql-snow {
    border: 1px solid#3E4247;
    border-radius: 9px;
/* outline: #3E4247; */
}
.ql-toolbar.ql-snow + .ql-container.ql-snow{
border-top: 1px solid #3E4247;
border-radius: 9px;
height: 40vh;
}

.ql-editor::-webkit-scrollbar {
    width: 20px;
}

.ql-editor{
    font-size: 1.2rem;
}

.ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg{
    filter: invert();
    height: 24px;
    width: 24px;
}
.ql-editor{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.ql-editor::before {
    color: grey !important;
    font-style: normal !important;
}

.quill_controller > :nth-child(2) {
    height: 10rem !important;
}

.ql-toolbar.ql-snow .ql-formats{
    margin-right: 0;
    display: flex;
    column-gap: 1rem;
}